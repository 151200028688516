import React from "react";
import { useState, useEffect } from "react";
import Select from "@mui/material/Select";
import BillingTable from "./BillingTable";
import MenuItem from "@mui/material/MenuItem";
import Goback from "../../src/assets/image/GoBack.svg";
import Logo from "../../src/assets/image/Forward.svg";
import IMAGE from "../../src/assets/image/demo.webp";
import { TablePagination } from "@mui/material";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import {
  Alert,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  // TablePagination,
  TableRow,
} from "@mui/material";
//   import React, { useEffect, useRef, useState } from "react";
import { customer, sales } from "../redux/reducer/handleCart";
import EditIcon from "../assets/image/edit.png";
import DeleteIcon from "../assets/image/trash.png";
import printIcon from "../assets/image/Print.svg";
//   import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//   import axios from "axios";
import store from "../redux/store";
import moment from "moment";
import AlertModal from "./AlertModal";
import showBill from "../assets/image/pasShow.png";

export default function CustomerList() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [deleteIndex, setDeleteIndex] = useState([]);
  const [printIndex, setPrintIndex] = useState([]);
  const [deleteConfirmAlert, setDeleteConfirmAlert] = useState(null);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [deleteSuccessfully, setDeleteSuccessfully] = useState(false);
  const [page, pageChange] = useState(0);
  const [rowperpage, rowperpageChange] = useState(6);
  const [sessionValue, setSessionValue] = useState([]);
  const dispatch = useDispatch();

  console.log("deleteConfirmAlert", deleteConfirmAlert);

  const getSalesDetail = async () => {
    return await axios.get(`${process.env.REACT_APP_HOST}/customers`);
  };
  const { data, isLoading, isError, refetch } = useQuery(
    "data",
    getSalesDetail
  );

  let details = data && data?.data ? data && data?.data : [];
  console.log("details", details);

  const [searchName, setSearchName] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const handleChange = (e) => {
    setSearchName(e.target.value);
  };
  const filteredProducts = details?.filter((product) => {
    const cleanedName = product.customerName.replace(/^(Dr|Mr|Miss|Mrs)\s/, "");
    return cleanedName?.toLowerCase().startsWith(searchName?.toLowerCase());
  });

  // const { state } = useLocation()

  let handlepageChange = (event, newpage) => {
    pageChange(newpage);
  };
  let handlerowsperpageChange = (event) => {
    rowperpageChange(+event.target.value);
    pageChange(0);
  };

  const HandlerDelete = async (id) => {
    // const confirmDelete = window.confirm("Are you sure you want to delete?");
    console.log("testId", id);

    if (deleteIndex) {
      deleteIndex &&
        deleteIndex?.map(async (val, i) => {
          try {
            await axios.delete(
              `${process.env.REACT_APP_HOST}/customers/${val}`
            );
            // alert("Delete Successfully");
            refetch();
            setDeleteSuccessfully(true);
            document.getElementById("myCheckbox").checked = false;
            // window.location.reload();
          } catch (error) {
            console.log("Error", error);
          }
        });
      console.log("Deleted!");
    }
    if (deleteConfirmAlert) {
      console.log("vignesh");
      try {
        await axios.delete(
          `${process.env.REACT_APP_HOST}/customers/${deleteConfirmAlert}`
        );
        // alert("Delete Successfully");
        refetch();
        setDeleteSuccessfully(true);
        // window.location.reload();
      } catch (error) {
        console.log("Error", error);
      }
    }
  };
  return (
    <>
      <div className="container product_container">
        <div className="salesViewHeadSection">
          <div className="salesViewHeadBlock">
            <div>
              <img src={Goback} onClick={() => navigate(-1)}></img>
            </div>

            <div className="product_header">
              <h2 className="product_view">Customer View</h2>
            </div>
          </div>

          <div className="salesViewSearchField">
            <div>
              <input
                type="search"
                placeholder="Search Name"
                onChange={handleChange}
              ></input>
            </div>
          </div>
        </div>

        {/* <div>
               <TableData data={filters} /> 
              <BillingTable
                data={details}
                searchResult={filteredProducts}
                searchName={searchName}
                isLoading={isLoading}
                isError={isError}
                refetch={refetch}
              />
            </div> */}
        <div className="me-5 pe-2 deleteBtnCss">
          <button
            onClick={() => {
              setDeleteConfirmAlert(deleteIndex);
            }}
            className="deleteBtn me-2"
            style={{
              display: deleteIndex < 1 ? "none" : "block",
            }}
          >
            Delete
          </button>
          {/* <button
          onClick={() => {
            // HandlerDelete();
            printProduct();
          }}
          className="deleteBtn"
          style={{
            display: printIndex < 1 ? "none" : "block",
          }}
        >
          Print
        </button> */}
        </div>
        <div className="container">
          <div className="row">
            <div className="product_table">
              <TableContainer className="product_table_container_sticky">
                <Table>
                  <TableHead className="product_heading_sticky ">
                    <TableRow>
                      {/* <TableCell className="sales_table_heading_first_sticky">
                      S.No
                    </TableCell> */}
                      <TableCell className="sales_table_heading">
                        Bill No
                      </TableCell>
                      <TableCell className="sales_table_heading">
                        Customer Name
                      </TableCell>
                      <TableCell className="sales_table_heading">
                        Customer Address
                      </TableCell>
                      <TableCell className="sales_table_heading">
                        District/State
                      </TableCell>

                      <TableCell className="sales_table_heading">
                        Pincode
                      </TableCell>
                      <TableCell className="sales_table_heading">
                        Contact
                      </TableCell>

                      <TableCell className="sales_table_heading">
                        GST No
                      </TableCell>
                      {/* <TableCell className="sales_table_heading">
                      Product
                    </TableCell> */}
                      <TableCell className="sales_table_heading">
                        Product Copy
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading && isLoading ? (
                      <TableRow
                        style={{ height: "180PX", position: "relative" }}
                      >
                        <div class="wrapper">
                          <div class="circle"></div>
                          <div class="circle"></div>
                          <div class="circle"></div>
                          <div class="shadow"></div>
                          <div class="shadow"></div>
                          <div class="shadow"></div>
                          <span>Loading</span>
                        </div>
                      </TableRow>
                    ) : isError && isError ? (
                      <TableRow>
                        <p
                          style={{
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: 800,
                          }}
                        >
                          No Network...
                        </p>
                      </TableRow>
                    ) : searchName?.length === 0 ? (
                      details &&
                      details
                        ?.slice(
                          page * rowperpage,
                          page * rowperpage + rowperpage
                        )
                        .map((val, i) => {
                          return (
                            <>
                              <TableRow key={i}>
                                {/* <TableCell className="sales_table_data_first_sticky">
                                {i+1}
                              </TableCell> */}
                                <TableCell className="sales_table_data">
                                  {val.id}
                                </TableCell>
                                <TableCell className="sales_table_data">
                                  {val.customerName}
                                </TableCell>
                                <TableCell className="sales_table_data--productName">
                                  {val.customerAddress}
                                </TableCell>
                                <TableCell className="sales_table_data--productName">
                                  {val.district}
                                </TableCell>
                                <TableCell className="sales_table_data">
                                  {val.pincode}
                                </TableCell>
                                <TableCell className="sales_table_data">
                                  {val.contactNumber}
                                </TableCell>
                                <TableCell className="sales_table_data">
                                  {val.gst}
                                </TableCell>
                                {/* <TableCell className="sales_table_data--productName">
                                {val?.userForm?.map((items, i) => {
                                  console.log("items", items);
                                  return `${items?.productName.toString()},`;
                                })}
                              </TableCell> */}
                                <TableCell className="sales_table_data">
                                  <img
                                    style={{ cursor: "pointer" }}
                                    src={EditIcon}
                                    alt="Edit icon"
                                    className="product_edit_icon"
                                    onClick={() =>
                                      navigate("/customerEdit", { state: val })
                                    }
                                  />

                                  {/* <img
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "10px",
                                  }}
                                  src={showBill}
                                  alt="print icon"
                                  className="sales_print_icon"
                                  value={val}
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      "salesInvoice",
                                      JSON.stringify(val)
                                    );
                                    navigate("/BillingInvoice", {
                                      state: printIndex,
                                    });
                                  }}
                                /> */}
                                  <input
                                    id="myCheckbox"
                                    onClick={() => {
                                      setDeleteIndex([...deleteIndex, val.id]);
                                    }}
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      marginRight: "10px",
                                    }}
                                    type="checkBox"
                                  />

                                  <img
                                    style={{
                                      cursor: "pointer",
                                      marginBottom: "10px",
                                    }}
                                    src={DeleteIcon}
                                    alt="Delete icon"
                                    className="sales_delete_icon"
                                    onClick={() =>
                                      //  HandlerDelete(val.id)
                                      // HandlerDelete(val.id)
                                      setDeleteConfirmAlert(val.id)
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })
                    ) : filteredProducts?.length > 0 ? (
                      filteredProducts
                        ?.slice(
                          page * rowperpage,
                          page * rowperpage + rowperpage
                        )
                        .map((val, i) => {
                          return (
                            <>
                              <TableRow key={i}>
                                <TableCell className="sales_table_data">
                                  {val.invoiceEntry}
                                </TableCell>
                                <TableCell className="sales_table_data">
                                  {val.customerName}
                                </TableCell>
                                <TableCell className="sales_table_data--customer">
                                  {val.customerAddress}
                                </TableCell>
                                <TableCell className="sales_table_data--customer">
                                  {val.district}
                                </TableCell>
                                <TableCell className="sales_table_data">
                                  {val.pincode}
                                </TableCell>
                                <TableCell className="sales_table_data">
                                  {val.contactNumber}
                                </TableCell>
                                <TableCell className="sales_table_data">
                                  {/* {val.salesDate} */}
                                  {moment(val.salesDate).format("MM/DD/YYYY")}
                                </TableCell>
                                {/* <TableCell className="sales_table_data--productName">
                                {val?.userForm?.map((items, i) => {
                                  console.log("items", items);
                                  return `${items?.productName.toString()},`;
                                })}
                              </TableCell> */}

                                <TableCell className="sales_table_data">
                                  <img
                                    style={{ cursor: "pointer" }}
                                    src={EditIcon}
                                    alt="Edit icon"
                                    className="product_edit_icon"
                                    onClick={() =>
                                      navigate("/customerEdit", { state: val })
                                    }
                                  />

                                  {/* <img
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "10px",
                                  }}
                                  src={showBill}
                                  alt="print icon"
                                  className="sales_print_icon"
                                  value={val}
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      "salesInvoice",
                                      JSON.stringify(val)
                                    );
                                    navigate("/BillingInvoice", {
                                      state: printIndex,
                                    });
                                  }}
                                /> */}
                                  <input
                                    id="myCheckbox"
                                    onClick={() => {
                                      setDeleteIndex([...deleteIndex, val.id]);
                                    }}
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      marginRight: "10px",
                                    }}
                                    type="checkBox"
                                  />
                                  <img
                                    style={{
                                      cursor: "pointer",
                                      marginBottom: "10px",
                                    }}
                                    src={DeleteIcon}
                                    alt="Delete icon"
                                    className="sales_delete_icon"
                                    onClick={() =>
                                      //  HandlerDelete(val.id)
                                      // HandlerDelete(val.id)
                                      setDeleteConfirmAlert(val.id)
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })
                    ) : (
                      <h4>No record found</h4>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>

        <div className="container footer_section">
          {searchResult?.length == 0 ? (
            <div className="row mt-3">
              <div className="col-6">
                <p className="product_paragraph_footer">
                  Showing {page + 1} to {rowperpage} of {details?.length}{" "}
                  entries
                </p>
              </div>
              <div className="col-6">
                {/* <div className="col-6"> */}
                <TablePagination
                  rowsPerPageOptions={[data && data?.data.length]}
                  rowsPerPage={rowperpage}
                  page={page}
                  count={data && data?.data.length}
                  component="div"
                  onPageChange={handlepageChange}
                  onRowsPerPageChange={handlerowsperpageChange}
                ></TablePagination>
                {/* </div> */}
              </div>
            </div>
          ) : (
            <div className="row mt-3">
              <div className="col-6">
                <p className="product_paragraph_footer">
                  Showing {page + 1} of{" "}
                  {Number(details && details.length / 6).toFixed()} entries{" "}
                  {Number(details && details && details.length / 6).toFixed() >
                  1
                    ? "Pages"
                    : "Page"}
                </p>
              </div>
              <div className="col-6">
                <TablePagination
                  rowsPerPageOptions={[data && data?.data.length]}
                  rowsPerPage={rowperpage}
                  page={page}
                  count={data && data?.data.length}
                  component="div"
                  onPageChange={handlepageChange}
                  onRowsPerPageChange={handlerowsperpageChange}
                ></TablePagination>
              </div>
            </div>
          )}
        </div>
        <AlertModal
          deleteConfirmAlert={deleteConfirmAlert}
          setDeleteConfirmAlert={setDeleteConfirmAlert}
          setDeleteSuccessfully={setDeleteSuccessfully}
          deleteSuccessfully={deleteSuccessfully}
          yesOnClick={() => {
            setConfirmAlert(true);
            setDeleteConfirmAlert(false);
            HandlerDelete();
          }}
        />
      </div>
    </>
  );
}
