import React from "react";
import { useState, useEffect } from "react";
import Select from "@mui/material/Select";
import BillingTable from "./BillingTable";
import MenuItem from "@mui/material/MenuItem";
import Goback from "../../src/assets/image/GoBack.svg";
import Logo from "../../src/assets/image/Forward.svg";
import IMAGE from "../../src/assets/image/demo.webp";
import { TablePagination } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";

export const getSalesDetail = async () => {
  return await axios.get(`${process.env.REACT_APP_HOST}/sales/all`);
};

const BillingView = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError, refetch } = useQuery(
    "data",
    getSalesDetail
  );

  let details = data && data?.data;

  console.log(details);

  // const [status, setStatus] = useState("All");
  // const [filters, setFilter] = useState(Data);
  const [searchName, setSearchName] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const handleChange = (e) => {
    setSearchName(e.target.value);
  };
  const filteredProducts = details?.filter((product) => {
    return product?.customerName
      ?.toLowerCase()
      // .startsWith(searchName?.toLowerCase());
      .includes(searchName?.toLowerCase());
  });
  return (
    <>
      <div className="container product_container">
        <div className="salesViewHeadSection">
          <div className="salesViewHeadBlock">
            <div>
              <img src={Goback} onClick={() => navigate(-1)}></img>
            </div>

            <div className="product_header">
              <h2 className="product_view">Sales View</h2>
            </div>
          </div>

          <div className="salesViewSearchField">
            <div>
              <input
                type="search"
                placeholder="Search Name"
                onChange={handleChange}
              ></input>
            </div>
          </div>
        </div>

        <div>
          {/* <TableData data={filters} /> */}
          <BillingTable
            data={details}
            searchResult={filteredProducts}
            searchName={searchName}
            isLoading={isLoading}
            isError={isError}
            refetch={refetch}
          />
        </div>
      </div>
    </>
  );
};

export default BillingView;
