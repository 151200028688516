import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Goback from "../assets/image/GoBack.svg";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import BillingAddInputField from "./BillingAddInputField";
import DatePicker from "react-datepicker";
import { useMutation, useQuery } from "react-query";
import {
  getCustomerRegister,
  getLastInvoice,
  getProductDetail,
} from "../api/GlobalApi";
import { Autocomplete, TextField } from "@mui/material";
import deleteIcon from "../assets/image/deleteIcon.svg";
import Add from "../assets/image/Add.svg";
import axios from "axios";
import AlertModal from "./AlertModal";

export default function CustomerEdit() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [UpdateOpen, setUpdateOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [productAdds, setProductAdds] = useState(false);
  const [productsAdds, setProductsAdds] = useState();
  const [isIndexs, setIsIndexs] = useState();
  const [shouldFetch, setShouldFetch] = useState(false);
  const [userFormValue, setUserFormValue] = useState([]);

  console.log("state", state);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const { userForm, gstType } = watch();
  const companyName = sessionStorage.getItem("head");
  const subCompanyName = sessionStorage.getItem("sub");
  const subCompanyList = JSON.parse(subCompanyName);

  const editMrMrs = state?.customerName.split(" ");

  const EditCustomerName = state?.customerName.substring(
    state?.customerName.indexOf(" ") + 1
  );

  useEffect(() => {
    if (state) {
      setValue("mrMrs", editMrMrs ? editMrMrs[0] : "");
      setValue("customerName", EditCustomerName);
      setValue("contactNumber", state?.contactNumber);
      setValue("customerAddress", state?.customerAddress);
      setValue("pincode", state?.pincode);
      setValue("district", state?.district);
      setValue("gst", state?.gst);
    }
  }, []);

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (postData) => {
      try {
        console.log("postData", postData);
        const response = await axios.post(
          `${process.env.REACT_APP_HOST}/customers`,
          postData
        );
        setOpen("customers record has been successfully added.");
        return response.data; // Ensure you return the response data so it can be accessed in onSuccess
      } catch (error) {
        console.error("Error in mutation:", error);
        setErrorOpen(
          error &&
            error?.response &&
            error?.response?.data &&
            error?.response?.data
        );
        throw error;
      }
    }
  );

  const postFormData = useMutation(
    async (postData) => {
      try {
        console.log("postData", postData);
        const response = await axios.put(
          `${process.env.REACT_APP_HOST}/customers/${state.id}`,
          postData
        );
        setOpen("customers record has been successfully updated.");
        return response.data; // Ensure you return the response data so it can be accessed in onSuccess
      } catch (error) {
        console.error("Error in mutation:", error);
        setErrorOpen(
          error &&
            error?.response &&
            error?.response?.data &&
            error?.response?.data
        );
        throw error;
      }
    }
  );

  const { mrMrs, customerName, transactionMode, gstPercentage } = watch();

  const onSubmit = (data) => {
    console.log("data", data);
    // console.log(
    //   "data?.mrMrs",
    //   `${(data?.mrMrs === undefined ? "" : data?.mrMrs)}${data?.customerName}`
    // );
    const value = {
      customerName: `${
        mrMrs === undefined ? "" : mrMrs ? `${mrMrs}` : ""
      }${" "}${customerName}`,
      customerAddress: data?.customerAddress,
      pincode: data?.pincode,
      contactNumber: data?.contactNumber,
      salesDate: data?.salesDate,
      transactionMode: data?.transactionMode,
      transactionStatus: data?.transactionStatus,
      headOffice: data?.headOffice,
      subOffice: data?.subOffice ? data?.subOffice : null,
      district: data?.district,
      billType: data?.billType,
      gst: data?.gst,
      gstType: data?.gstType,
      gstPercentage: data?.gstPercentage,
      invoiceEntry: data?.invoiceEntry,
      userForm,
    };
    console.log(" OnSubimt", value);
    if (state && state?.id) {
      console.log("state?.id", value);
      postFormData.mutate(value);
    } else {
      mutate(value);
    }
    // if (state && state?.id) {
    //   console.log("state?.id", value);
    //   mutate(value);
    //   //   sessionStorage.setItem("salesInvoice", JSON.stringify(value));
    //   //   postFormData.mutate(value);
    // }
    // else {
    //   console.log("BillingData", value);
    //   sessionStorage.setItem("salesInvoice", JSON.stringify(value));

    //   ;
    // }
  };
  return (
    <div className="main-container" style={{ width: "" }}>
      <div className="BillingAddHeader">
        <div className="Billing-Add-GoBackBtn">
          <img src={Goback} alt="no-image" onClick={() => navigate(-1)} />
        </div>

        <div className="billing-heading-viewblock">
          <div>
            <h3 className="Billng-Add-Heading">Customer Add</h3>
          </div>

          <div className="Billing-Add-ViewBlock">
            <button
              className="Billing-Add-ViewBtn"
              onClick={() => {
                navigate("/BillingView");
              }}
            >
              View
            </button>
          </div>
        </div>
      </div>
      <div
        className="BillingAddMainBlock"
        style={{ border: "none", overflow: "hidden" }}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="BillingAddFormBlock">
          <div className="BillingAddInputContainer">
            <div className="BillingAddInputField ">
              <label className="BillingAddInputLabel">Mr/Mrs</label>
              <Controller
                control={control}
                name="mrMrs"
                defaultValue="Mr"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <select
                    value={value}
                    onChange={onChange}
                    className="billing_input_field"
                    placeholder="Select"
                    style={{
                      "text-transform": "capitalize",
                    }}
                  >
                    {/* <option value=" ">None</option> */}
                    <option value="Miss">Miss</option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                  </select>
                )}
              />
            </div>

            <div className="BillingAddInputField ">
              <label className="BillingAddInputLabel">
                Customer Name{" "}
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
              </label>
              <Controller
                control={control}
                name="customerName"
                render={(field) => (
                  <BillingAddInputField {...field} type="text" />
                )}
                rules={{ required: true }}
              />
            </div>
            <div className="BillingAddInputField">
              <label className="BillingAddInputLabel">
                Phone Number
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
              </label>
              <Controller
                control={control}
                name="contactNumber"
                render={(field) => (
                  <BillingAddInputField {...field} type="text" />
                )}
                rules={{ required: true }}
              />
            </div>
            <div className="BillingAddInputField">
              <label className="BillingAddInputLabel">
                Customer Address
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
              </label>
              <Controller
                control={control}
                name="customerAddress"
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <textarea
                    style={error ? { borderColor: "red" } : {}}
                    value={value}
                    onChange={onChange}
                    // onFocus={() => setIsFocused(true)}
                    // onBlur={() => setIsFocused(false)}
                    className="billing_input_field_select"
                  ></textarea>
                )}
                rules={{ required: true }}
              />
            </div>
            <div className="BillingAddInputField">
              <label className="BillingAddInputLabel">District/State </label>
              <Controller
                control={control}
                name="district"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    // placeholder="603103"
                    type="text"
                  />
                )}
              />
            </div>
            <div className="BillingAddInputField">
              <label className="BillingAddInputLabel">Pincode</label>
              <Controller
                control={control}
                name="pincode"
                render={(field) => (
                  <BillingAddInputField {...field} type="number" />
                )}
              />
            </div>
            
            {/* <div className="BillingAddInputField">
              <label className="BillingAddInputLabel">
                Bill Type
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
              </label>
              <Controller
                control={control}
                name="billType"
                defaultValue="Tax Invoice"
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <select
                    value={value}
                    onChange={onChange}
                    className="text-capitalize billing_input_field"
                    placeholder="Select"
                    helperText={error ? "This field is required" : ""}
                    style={error ? { borderColor: "red" } : {}}
                  >
                    
                    <option value="Tax Invoice">Tax Invoice</option>
                    <option value="Quotation">Quotation</option>
                    <option value="Proforma Invoice">Proforma Invoice</option>
                    {error ? "This field is required" : ""}
                  </select>
                )}
                rules={{ required: true }}
              />
            </div> */}
            {/* <div className="BillingAddInputField">
              <label className="BillingAddInputLabel">GST No</label>
              <Controller
                control={control}
                name="gst"
                render={(field) => (
                  <BillingAddInputField {...field} type="text" />
                )}
              />
            </div> */}
            {/* <div className="BillingAddInputField ">
              <label className="BillingAddInputLabel">GST Type</label>
              <Controller
                control={control}
                name="gstType"
                defaultValue="national"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <select
                    value={value}
                    onChange={onChange}
                    className="billing_input_field"
                    placeholder="Select"
                    style={{
                      "text-transform": "capitalize",
                    }}
                  >
                    <option value="national">national</option>
                    <option value="international">international</option>
                  </select>
                )}
              />
            </div> */}
            {/* {gstType ? (
              <div className="BillingAddInputField ">
                <label className="BillingAddInputLabel">
                  GST %{" "}
                  <span style={{ color: "red", fontSize: "14px" }}>*</span>
                </label>
                <Controller
                  control={control}
                  name="gstPercentage"
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <input
                      type="text"
                      value={value}
                      onChange={onChange}
                      className="text-capitalize billing_input_field"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            ) : (
              ""
            )} */}
            {/* <div className="BillingAddInputField">
              <label className="BillingAddInputLabel">
                Bill No
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
              </label>
              <Controller
                control={control}
                name="invoiceEntry"
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <input
                    type="text"
                    value={value}
                    onChange={onChange}
                    className="text-capitalize billing_input_field"
                  />
                )}
                rules={{ required: true }}
              />
            </div> */}
            <div className="BillingAddInputField">
                <label className="BillingAddInputLabel">GST No</label>
                <Controller
                  control={control}
                  name="gst"
                  render={(field) => (
                    <BillingAddInputField {...field} type="text" />
                  )}
                />
              </div>

            {/* {(companyName || subCompanyName) && (
                <div className="BillingAddInputField ">
                  <label className="BillingAddInputLabel">Head Office</label>
                  <Controller
                    control={control}
                    name="headOffice"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <input
                        type="text"
                        className="billing_input_field"
                        value={value}
                      />
                    )}
                  />
                </div>
              )}
              {subOfficeName.length == 0 ? (
                <></>
              ) : companyName ? (
                <div className="BillingAddInputField ">
                  <label className="BillingAddInputLabel">Branch Office</label>
                  <Controller
                    control={control}
                    name="subOffice"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        value={value}
                        onChange={onChange}
                        className="billing_input_field"
                        // placeholder="Select"
                        style={{
                          "text-transform": "capitalize",
                        }}
                      >
                        <option value="">Select Branch</option>
                        {subOfficeName?.map((data, index) => (
                          <option key={index} value={data?.shopName}>
                            {data.shopName}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                </div>
              ) : (
                <div className="BillingAddInputField ">
                  <label className="BillingAddInputLabel">Branch Office</label>
                  <Controller
                    control={control}
                    name="subOffice"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <input
                        type="text"
                        className="billing_input_field"
                        value={value}
                      />
                    )}
                  />
                </div>
              )} */}
          </div>

          {/* <div className="d-flex ">
            <div className="BillingAddInputField ">
              <label className="BillingAddInputLabel">
                Transaction Mode
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
              </label>
              <Controller
                control={control}
                name="transactionMode"
                defaultValue="Online"
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <select
                    value={value}
                    onChange={onChange}
                    className="billing_input_field"
                    style={error ? { borderColor: "red" } : {}}
                  >
                    <option value="Cash">Cash</option>
                    <option value="Online">Online</option>
                    <option value="credit">credit</option>
                  </select>
                )}
                rules={{ required: true }}
              />
            </div>
            <div className="BillingAddInputField ">
              <label className="BillingAddInputLabel">
                Transaction Status
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
              </label>
              <Controller
                control={control}
                name="transactionStatus"
                defaultValue="Completed"
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <select
                    value={value}
                    onChange={onChange}
                    className=" text-capitalize billing_input_field"
                    placeholder="Select"
                    style={error ? { borderColor: "red" } : {}}
                  >
                    <option value="Completed">completed</option>
                    <option value="Pending">pending</option>
                  </select>
                )}
                rules={{ required: true }}
              />
            </div>
          </div> */}
          <div
            className="ms-3 BillingAddSubmitBlock"
            style={{ display: "flex", gap: "10px" }}
          >
            <div>
              <button type="submit" className="BillingAddSubmitBtn">
                {isLoading ? (
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "save"
                )}
              </button>
            </div>
            <></>
          </div>
        </form>
      </div>
      <AlertModal
        open={open}
        setOpen={setOpen}
        // handleClose={handleClose}
        setUpdateOpen={setUpdateOpen}
        UpdateOpen={UpdateOpen}
        // val={productAdds ? productsAdds : ""}
        navigate={"/customerList"}
        setErrorOpen={setErrorOpen}
        errorOpen={errorOpen}
      />
    </div>
  );
}
